import styled from "styled-components";
import WhyChoose from "../../assets/whyChose.png";
const Style = styled.div`
  margin-top: 79px;
  .main-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    h6 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 80.52%;
      color: #0077d3;
    }
    h1 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 69.52%;
      color: #2d2d2d;
      @media (max-width:480px) {
        font-size: 30px;
      }
    }
  }
  .service-card {
    background: #ffffff;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    width: 300px;
    @media (max-width:320px){
      width: 280px;
    }
    &-img {
      /* width: 300px; */
      height: 191px;
      border-radius: 10px 10px 0px 0px;
      @media (max-width:320px){
      width: 280px;
    }
    }
    &-data {
      padding: 20px 20px;
      &-heading {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 80.52%;
        color: #2d2d2d;
        padding-bottom: 14px;
      }
      &-paragraph {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #5f5f5f;
      }
      &-button {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 80.52%;
        text-decoration-line: underline;
        color: #0077d3;
        margin-top: 26px;
        border: none;
        background: transparent;
      }
    }
  }
  .card-container {
    display: flex;
    flex-direction: row;
    gap: 30px;
    flex-wrap: wrap;
    margin-top: 60px;
    @media (max-width:1399px){
      justify-content: center;
    }
  }
  .solution {
    margin-top: 93px;
    position: relative;
    &-container {
      display: flex;
      @media (max-width:1199px){
        flex-direction: column;
      }
    }
    &-img {
      border-radius: 18px 0px 0px 18px;
      @media (max-width:1199px){
        width: 100%;
        border-radius: 18px !important;
        height: 351px;
      }
    }
    &-data {
      display: flex;
      flex-direction: column;
      gap: 18px;
      align-items: center;
      justify-content: center;
      background: #0077d3;
      width: 100%;
      border-radius: 0px 18px 18px 0px;
      h2 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 136%;
        color: #fff;
        max-width: 475px;
        @media (max-width:600px){
          font-size: 25px;
        }
      }
      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        max-width: 475px;
        padding-right: 20px;
        color: #ffffff;
        @media (max-width:600px){
          font-size: 16px;
        }
      }
      @media (max-width:1199px){
        height: 351px;
        border-radius: 18px;
      }
      @media (max-width:600px){
        padding: 20px;
      }
    }
    &-logo {
      width: 130px;
      height: 130px;
      position: absolute;
      top: 30%;
      left: 44%;
      @media (max-width:1199px){
        top: 40%;
        left: 40%;
      }
    }
  }
  .whyChoose {
    background: url(${WhyChoose});
    width: 100%;
    min-height: 832px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: 79px;
    &-container {
      max-width: 630px;
      padding: 79px 0;
    }
    &-heading {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 130%;
      color: #2d2d2d;
      &-small {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 80.52%;
        color: #0077d3;
        padding-bottom: 7px;
      }
    }
    &-para {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 80.52%;
      color: #555555;
      padding-top: 13px;
    }
    &-data {
      margin-top: 68px;
      display: flex;
      flex-direction: column;
      gap: 51px;
      //justify-content: space-between;
    }
  }
  .rows {
    display: flex;
    gap: 30px;
    &-icons {
      padding-top: 10px;
      width: 80px;
      height: 80px;
    }
    &-data {
      max-width: 520px;
      h5 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 80.52%;
        color: #2d2d2d;
        
      }
      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 148%;
        color: #626262;
        padding-top: 10px;
      }
    }
  }
`;

export default Style;
