import styled from "styled-components";

const Style = styled.div`
  background-color: #0077d3;
  /* font-family: "Poppins"; */
  .footer_container {
    color: #fff;
    padding-top: 48px;
    padding-bottom: 31px;
  }
  .firstrow {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    color: #fff;
    margin-bottom: 70px;
  }
  .footr_bottom {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 85.02%;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    margin-top: 37px;
    flex-wrap: wrap;
    @media screen and (max-width : 768px){
      line-height: 25px;
    }
  }
  .line {
    width: 100%;
    height: 2px;
    background: #008ddd;
  }
  .first_column {
    width: 371px;
    gap: 18px;
    display: flex;
    flex-direction: column;
    img {
      height: 97px;
      width: 103px;
    }
  }
  .paragraph {
    /* width: 371px;
        padding-right: 90px; */
  }
  .second-column {
    width: 103px;
    margin-top: 15px;
    &-row {
      margin-top: 41px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
  .third-column {
    width: 150px;
    margin-top: 15px;
    &-row {
      margin-top: 41px;
      display: flex;
      flex-direction: row;
      gap: 30px;
      img {
        width: 32px;
        height: 32px;
      }
    }
  }
  .fourth-column{
    width: 264px;
    margin-top: 15px;
    &-row{
      margin-top: 41px;
      display: flex;
      flex-direction: column;
      gap:35px;
      &-data{
        display: flex;
        flex-direction: row;
        gap: 12px;
      }
    }
  }
`;

export default Style;
