import React from "react";
import Style from "./Style";

const Index = () => {
  return (
    <div className="container">
      <Style>
        <div className="first-column"></div>
        <div className="second-column">
          <h3>Contact Us Now</h3>
          <form className="contact-form">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="Name"
              />
            </div>
            <div class="form-group">
              <input
                type="email"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="name@example.com"
              />
            </div>
            <div class="form-group">
              <input
                type="tel"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="Phone"
              />
            </div>
            <div class="form-group">
              <select class="form-control">
                <option>Residential</option>
              </select>
            </div>
            <div class="form-group">
              <textarea
                class="form-textarea"
                id="exampleFormControlTextarea1"
                rows="3"
                placeholder="Message"
              ></textarea>
            </div>
            <button type="submit" className="submit-button">Send</button>
          </form>
        </div>
      </Style>
    </div>
  );
};

export default Index;
