import React from "react";
import Style from "./Style";
import HomeConstruction from "../../assets/homeConstruction.png";
import HomeRepairing from "../../assets/homeRepairing.png";
import OfficeConstruction from "../../assets/officeConstruction.png";
import OfficeRepairing from "../../assets/officeRepairing.png";
import Solution from "../../assets/solution.png";
import SolutionLogo from "../../assets/Icons/solution-logo.svg";
import BuildingMaterial from "../../assets/Icons/buildingMaterial.svg";
import AffordablePrice from "../../assets/Icons/affordablePrice.svg";
import ExperienceWork from "../../assets/Icons/experienceWork.svg";
import ModrenTechnologies from "../../assets/Icons/modrenTechnologies.svg";
const Index = () => {
  return (
    <Style>
      <div className="container">
        <div className="main-heading">
          <h6>Services</h6>
          <h1>Our Best Services</h1>
        </div>
        <div className="card-container">
          <div className="service-card">
            <img src={HomeConstruction} className="service-card-img" />
            <div className="service-card-data">
              <h3 className="service-card-data-heading">Home Construction</h3>
              <p className="service-card-data-paragraph">
                Mattis mattis tempor a aliquam vel facilisis mattis ornare
                blandit tempor a aliquam .
              </p>
              <button className="service-card-data-button">
                Read more &#8594;
              </button>
            </div>
          </div>
          <div className="service-card">
            <img src={HomeRepairing} className="service-card-img" />
            <div className="service-card-data">
              <h3 className="service-card-data-heading">Home Repairing</h3>
              <p className="service-card-data-paragraph">
                Mattis mattis tempor a aliquam vel facilisis mattis ornare
                blandit tempor a aliquam .
              </p>
              <button className="service-card-data-button">
                Read more &#8594;
              </button>
            </div>
          </div>
          <div className="service-card">
            <img src={OfficeConstruction} className="service-card-img" />
            <div className="service-card-data">
              <h3 className="service-card-data-heading">Office Construction</h3>
              <p className="service-card-data-paragraph">
                Mattis mattis tempor a aliquam vel facilisis mattis ornare
                blandit tempor a aliquam .
              </p>
              <button className="service-card-data-button">
                Read more &#8594;
              </button>
            </div>
          </div>
          <div className="service-card">
            <img src={OfficeRepairing} className="service-card-img" />
            <div className="service-card-data">
              <h3 className="service-card-data-heading">Office Repairing</h3>
              <p className="service-card-data-paragraph">
                Mattis mattis tempor a aliquam vel facilisis mattis ornare
                blandit tempor a aliquam .
              </p>
              <button className="service-card-data-button">
                Read more &#8594;
              </button>
            </div>
          </div>
        </div>
        <div className="solution">
          <div className="solution-container">
            <div>
              <img src={Solution} className="solution-img" />
            </div>
            <div className="solution-data">
              <h2>The Best Solution For Your Next Construction Project</h2>
              <p>
                Dreams are meant to come true. We’ll make it happen for you.
              </p>
            </div>
          </div>
          <img src={SolutionLogo} className="solution-logo" />
        </div>
      </div>
      <section className="whyChoose">
        <div className="container">
          <div className="whyChoose-container">
            <div>
              <p className="whyChoose-heading-small">
                Why Choose Us?
              </p>
              <h2 classname="whyChoose-heading">
                BURX Construction Company
              </h2>
              <p className="whyChoose-para">
                We build reliable and modern stuff which last long!
              </p>
            </div>
            <div className="whyChoose-data">
                <div className="rows">
                    <div>
                    <img src={BuildingMaterial} className="rows-icons" alt="Why choose-icons"/>
                    </div>
                    <div className="rows-data">
                        <h5>Best Builing Material</h5>
                        <p>We make the construction & repairing easier for you to provide best quality service to give you your free time back so that you can concentrate on what is important.</p>
                    </div>
                </div>
                <div className="rows">
                    <div>
                    <img src={ExperienceWork} className="rows-icons" alt="Why choose-icons"/>
                    </div>
                    <div className="rows-data">
                        <h5>Experience Workers</h5>
                        <p>We make the construction & repairing easier for you to provide best quality service to give you your free time back so that you can concentrate on what is important.</p>
                    </div>
                </div>
                <div className="rows">
                    <div>
                    <img src={AffordablePrice} className="rows-icons" alt="Why choose-icons"/>
                    </div>
                    <div className="rows-data">
                        <h5>Affordable Pricing</h5>
                        <p>We make the construction & repairing easier for you to provide best quality service to give you your free time back so that you can concentrate on what is important.</p>
                    </div>
                </div>
                <div className="rows">
                    <div>
                    <img src={ModrenTechnologies} className="rows-icons" alt="Why choose-icons"/>
                    </div>
                    <div className="rows-data">
                        <h5>Modern Technologies</h5>
                        <p>We make the construction & repairing easier for you to provide best quality service to give you your free time back so that you can concentrate on what is important.</p>
                    </div>
                </div>
                
            </div>
          </div>
        </div>
      </section>
    </Style>
  );
};

export default Index;
