import React, { useState } from "react";
import Style from "./Style";
import Facebook from "../../assets/Icons/Facebook.svg";
import Twitter from "../../assets/Icons/twitter.svg";
import Instagram from "../../assets/Icons/instagram.svg";
import Banner_icon from "../../assets/Icons/Frame_1.svg";
import Call from "../../assets/Icons/Call.svg";
import Home from "../../assets/Icons/Home.svg";
import Logo from '../../assets/Logo.png';
import Repair from '../../assets/Icons/Repair.svg';
import Commercial from '../../assets/Icons/Commercial.svg';
import Construction from '../../assets/Icons/Construction.svg';
import Remodel from '../../assets/Icons/Remodel.svg';
import Menu_icon from '../../assets/Icons/menu_icon.png';
import AboutUs from '../../assets/aboutUs.png';
const Index = () => {
  const[state,setState]=useState(true);
  return (
    <Style>
      <div className="top_nav">
        <div className="container">
          <div className="icons">
            <div className="top_nav_leftside_icons">
              <img src={Facebook} alt="icons" />
              <img src={Twitter} alt="icons" />
              <img src={Instagram} alt="icons" />
              <img src={Banner_icon} alt="icons" />
            </div>
            <div className="top_nav_rightside_icons">
              <img src={Call} alt="icons" />
              <span>123 123 4567</span>
            </div>
          </div>
        </div>
      </div>
      <section className="hero_section">
        <div className="container">
        <div className="navbar">
            <div class={state? "navbar-nav": "navbar-nav toggler"}>
              <a class="nav-item nav-link active" href="#">
                Home
              </a>
              <a class="nav-item nav-link" href="#">
              About Us
              </a>
              <a class="nav-item nav-link" href="#">
              Services
              </a>
              <a class="nav-item nav-link" href="#">
              Contact
              </a>
              <button className="estimate_button">
              Free Estimate
              </button>
              
            </div>
            <img src={Menu_icon} className="menuIcon" onClick={()=>setState(!state)}/>
        </div>
          <div className="logo">
            <img src={Logo} alt="Main_Logo"/>
          </div>
          <div className="mainHeading_box">
              <h2>
              IF IT’S BROKEN, <span style={{color:'#FFA800'}}>BURX</span> WILL FIX-IT
              </h2>
          </div>
          <div className="lines">
               <div className="lines_white"></div>
               <div className="lines_yellow"></div>
          </div>
          <div className="services">
            <div className="first_row">
              <div className="first_row_item">
                <img src={Home} alt="home"/>
                <h5>Home</h5>
              </div>
              <div className="first_row_item">
                <img src={Commercial} alt="home"/>
                <h5>Commercial</h5>
              </div>
              <div className="first_row_item">
                <img src={Repair} alt="home"/>
                <h5>Repair</h5>
              </div>
            </div>

            <div className="second_row">
            <div className="first_row_item">
                <img src={Remodel} alt="home"/>
                <h5>Remodel</h5>
              </div>
            <div className="first_row_item">
                <img src={Construction} alt="home"/>
                <h5>Construction</h5>
              </div>
            
            </div>
          </div>
        </div>
      </section>
      <section className="about-us">
      <div className="container">
      <div className="about-us-section">
            <div>
              <img src={AboutUs} className="about-us-img"/>
            </div>
            <div className="about-us-2ndcolumn">
              <p className="about-us-2ndcolumn-small">About us</p>
              <h4 className="about-us-2ndcolumn-large"> We are the Best Construction Service Provider </h4>
              <p className="about-us-2ndcolumn-para">We make the construction & repairing easier for you to provide best quality service to give you your free time back so that you can concentrate on what is important.</p>
              <button className="about-us-2ndcolumn-btn">Learn More</button>
            </div>
            </div>
            </div>
      </section>
    </Style>
  );
};

export default Index;
