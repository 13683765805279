import styled from "styled-components";
import Contactus from "../../assets/ContactUS.png";
const Style = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 80px;
  margin-bottom: 91px;
  @media (max-width:1024px){
      grid-template-columns: 1fr;
    }
  .first-column {
    background: url(${Contactus});
    width: 100%;
    height: 627px;
    border-radius: 0px 0px 0px 20px;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width:1024px){
      border-radius: 20px 20px 0px 0px;
    }

  }
  .second-column {
    background: #f2f5f8;
    border-radius: 0px 20px 20px 0px;
    padding: 50px 30px;
    @media (max-width:1024px){
      border-radius: 0px 0px 20px 20px;
    }
    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 32px;
      align-items: center;
      text-align: center;
      letter-spacing: -1px;
      color: #2d2d2d;
      @media (max-width:330px){
      font-size: 25px;
    }
    }
  }
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 37px;
  }
  .submit-button {
    width: 160px;
    height: 48px;
    background: #0077d3;
    border-radius: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 80.52%;
    color: #fff;
    border: none;
    margin-left: auto;
    margin-top: 16px;
  }
  .form-control{
    height:45px;
  }
  .form-textarea{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    height: 116px;
  }
`;

export default Style;
